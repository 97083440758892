import React from "react"
import { Link } from "gatsby"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import * as css from "./post-card.module.css"

const PostCard = (props) => {

  const thumb = getImage(props.node.frontmatter.thumbnail)

  return <article
    className={`post-card ${props.count % 3 === 0 && `post-card-large`} ${props.postClass
      } ${thumb ? `with-image` : `no-image`}`}
  >
    {thumb && <GatsbyImage
      className={css.heroImage}
      layout="fullWidth"
      image={thumb}
      alt=""
    />
    }
    <Link to={props.node.fields.slug} className={`${css.heroImage} post-card-link`}>
      <div className="post-card-content">
        <h2 className="post-card-title">
          {props.node.frontmatter.title || props.node.fields.slug}
        </h2>
      </div>
    </Link>
  </article >
}

export default PostCard

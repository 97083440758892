import React, { useState } from "react"
import { graphql, StaticQuery, Link } from "gatsby"
import * as styles from "./index.module.css"
import AnchorLink from "react-anchor-link-smooth-scroll"

import Layout from "../components/layout"
import Seo from "../components/seo"
import PostCard from "../components/post-card"
import ContentWrapper from "../components/content-wrapper"
import ReactPlayer from "react-player/lazy"
import VisibilitySensor from "react-visibility-sensor"
import { GatsbyImage, getImage } from "gatsby-plugin-image"


// import "../utils/global.scss"
import "../utils/normalize.css"
import "../utils/css/screen.css"

const BlogIndex = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title
  const posts = data.allMarkdownRemark.edges
  const heroImage = getImage(data.file);
  let postCounter = 0
  const [showVideo, updateShowVideo] = useState(false)
  const [playingVideo, updatePlayingVideo] = useState(false)

  return (
    <Layout bigImage={true} title={siteTitle}>
      <Seo
        title="Projetos multimédia"
        keywords={[`vídeo`, `multimédia`, `edição`]}
      />
      <div className={styles.headlineContainer}>
        <GatsbyImage
          className={styles.heroImage}
          layout="fullWidth"
          image={heroImage}
          alt=""
        />
        <div className={styles.headLineContent}>
          <div className={styles.introContainer}>
            {!showVideo && (
              <header
                onClick={() => {
                  updateShowVideo(true)
                  updatePlayingVideo(true)
                }}
                className={styles.pageHead}
              >
                <span className={styles.videoButtonContainer}>
                  <img alt="Mostrar vídeo" className={styles.aPlayIcon} src="/a-play.png" />
                </span>
              </header>
            )}
            <div
              className={`${styles.mainVideoContainer} ${showVideo && styles.mainVideoContainerVisible
                }`}
            >
              <div className={styles.closeVideoContainer}>
                <span onClick={() => {
                  updatePlayingVideo(false)
                  updateShowVideo(false)
                }} className={styles.closeVideoBtn}
                >Fechar</span></div>
              <div style={{ padding: "42.19% 0 0 0", position: "relative" }}>
                <ReactPlayer
                  onPlay={() => {
                    updatePlayingVideo(true)
                  }}
                  className={styles.videoPlayer}
                  playing={playingVideo}
                  width="100%"
                  height="100%"
                  controls={true}
                  url="https://player.vimeo.com/video/661430256"
                />
              </div>
            </div>
          </div>
          <div className={styles.goProjectsContainer}>
            <AnchorLink href="#projects">
              <div className={styles.showProjectsButton}>Projectos</div>
              <div className={styles.triangle}></div>
            </AnchorLink>
          </div>
        </div>
      </div>
      <div id="projects"></div>
      <ContentWrapper>
        <VisibilitySensor
          onChange={(isVisible) => {
            isVisible && updatePlayingVideo(false)
          }}
        >
          <div className={styles.titleSpace}>
            <h1>Projetos</h1>
          </div>
        </VisibilitySensor>
        <div className="post-feed">
          {posts.map(({ node }) => {
            postCounter++
            return (
              <PostCard
                key={node.fields.slug}
                count={postCounter}
                node={node}
                postClass={`post`}
              />
            )
          })}
        </div>
        <div className={styles.mainLink}>
          <Link to="/projetos"> Ver todos os projetos &gt;</Link>
        </div>
      </ContentWrapper>
    </Layout>
  )
}

const indexQuery = graphql`
  {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 6
      filter: { frontmatter: { homepage: { eq: true } } }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            thumbnail {
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  quality: 100
                  placeholder: BLURRED
                )
              }
            }
          }
        }
      }
    }
    file(relativePath: {eq: "header.jpg"}) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          quality: 100
          placeholder: BLURRED
        )
      }
    }
  }
`

const Index = (props) => (
  <StaticQuery
    query={indexQuery}
    render={(data) => (
      <BlogIndex location={props.location} props data={data} {...props} />
    )}
  />
)

export default Index

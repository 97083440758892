// extracted by mini-css-extract-plugin
export var introContainer = "index-module--introContainer--F5t5p";
export var mainVideoContainer = "index-module--mainVideoContainer--6gl3N";
export var mainVideoContainerVisible = "index-module--mainVideoContainerVisible--MqT+C";
export var headlineContainer = "index-module--headline-container--EqSWw";
export var heroImage = "index-module--heroImage--kyI1j";
export var headLineContent = "index-module--headLineContent--U5d9x";
export var pageHead = "index-module--page-head--n1zPX";
export var pageHeadTitle = "index-module--page-head-title--9tMfd";
export var pageHeadDescription = "index-module--page-head-description--2526b";
export var videoButtonContainer = "index-module--videoButtonContainer--0ePHt";
export var descriptionContainer = "index-module--descriptionContainer--cBc76";
export var description = "index-module--description--Orz8e";
export var titleSpace = "index-module--titleSpace--TG0Ix";
export var mainLink = "index-module--main-link--djVUD";
export var aPlayIcon = "index-module--aPlayIcon--F1S0F";
export var videoPlayer = "index-module--videoPlayer--qP+Dx";
export var goProjectsContainer = "index-module--goProjectsContainer--G5VJC";
export var showProjectsButton = "index-module--showProjectsButton--t0VQD";
export var triangle = "index-module--triangle--qWSTw";
export var alignIntro = "index-module--alignIntro---OS70";
export var closeVideoContainer = "index-module--closeVideoContainer--gAG7w";
export var closeVideoBtn = "index-module--closeVideoBtn--C3X4n";